import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';

const REFRESH_INTERVAL = 15 * 60 * 1000;
setInterval(() => {
  window.location.reload(true);
}, REFRESH_INTERVAL);

ReactDOM.render(
  <div style={{ padding: '20px' }}>Nothing here :)</div>,
  document.getElementById('root')
);
